<template>
  <div class="search-block">
    <div class="popcontext">
      <ul class="tab">
        <li>
          <b style="font-size: 16px">公司概况</b>
        </li>
        <li
          rel="hgroup-booking"
          @click="selectPannel('introduction')"
          :class="currentTab == 'introduction' ? 'active' : ''"
          id="li_dynamic"
        >
          <span>湖南航空简介</span>
        </li>
        <!--<li rel="hgroup-cuss" id="ga_cn_ybdj"><span>预办登机</span></li>-->
        <li
          rel="hgroup-aoc"
          :class="currentTab == 'culture' ? 'active' : ''"
          id="li_culture"
          @click="selectPannel('culture')"
        >
          <span>企业文化</span>
        </li>
        <li
          rel="hgroup-aoc"
          :class="currentTab == 'model' ? 'active' : ''"
          id="li_model"
          @click="selectPannel('model')"
        >
          <span>机型展示</span>
        </li>
        <li
          rel="hgroup-aoc"
          :class="currentTab == 'staff' ? 'active' : ''"
          id="li_staff"
          @click="selectPannel('staff')"
        >
          <span>员工风采</span>
        </li>
        <li
          rel="hgroup-gg"
          :class="currentTab == 'advertising' ? 'active' : ''"
          id="li_gg"
          @click="selectPannel('advertising')"
        >
          <span>广告招商</span>
        </li>
        <li
          rel="hgroup-contact"
          :class="currentTab == 'contact' ? 'active' : ''"
          id="li_contact"
          @click="selectPannel('contact')"
        >
          <span>联系我们</span>
        </li>
        <li
          rel="hgroup-news"
          :class="currentTab == 'news' ? 'active' : ''"
          id="li_news"
          @click="selectPannel('news')"
        >
          <span>新闻公告</span>
        </li>
      </ul>
    </div>
  </div>
</template>
  <script>
export default {
  props: ["activeIndex"],
  data() {
    return {
      currentTab: "",
      input: "",
    };
  },
  mounted: function () {
    this.currentTab = this.activeIndex;
    console.log(this.currentTab);
  },
  watch: {
    activeIndex (data) {
        console.log(data);
        this.currentTab=data;
    }
  },
  methods: {
    selectPannel(pannelId) {
      this.currentTab = pannelId;
      this.$router.push({
          path: "/company/" + pannelId || '',
          query: { },
        });



      // if (pannelId == "introduction") {
      //   this.$router.push({
      //     path: "/company/introduction",
      //     query: { },
      //   });
      // }
      // if (pannelId == "culture") {
      //   this.$router.push({
      //     path: "/company/culture",
      //     query: { },
      //   });
      // } else if (pannelId == "advertising") {
      //   this.$router.push({
      //     path: "/company/advertising",
      //     query: { },
      //   });
      // } else if (pannelId == "contact") {
      //   this.$router.push({
      //     path: "/company/contact",
      //     query: {  },
      //   });
      // } else if (pannelId == "li_news") {
      //   this.$router.push({
      //     path: "/company/news",
      //     query: {  },
      //   });
      // }
    },
  },
};
</script>
  
  <style scoped>
.search-block {
  width: 1200px;
  margin: auto;
  position: relative;
}
.popcontext {
  width: 240px;
  box-shadow: rgb(234, 234, 234) 0px 1px 5px 1px;
  /* border: 1px solid rgb(238, 238, 238); */
  min-height: 200px;
  /* background-color: #f8f8f8ff; */
}
.popcontext .tab {
  z-index: 1000;
  width: 240px;
}
.popcontext .tab li {
  height: 45px;
  line-height: 45px;
  /* border-top: 1px solid #4e617f; */
  /* background-color: #f8f8f8ff; */
  color: #000000;
  font-size: 14px;
  text-align: center;
  transition: all 0.3s ease 0s;
  -webkit-transition: all 0.5s ease 0s;
  cursor: pointer;
  /* margin-left: 10px; */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  filter: alpha(Opacity=60);
  -moz-opacity: 0.6;
  opacity: 0.6;
}

/* border-bottom: 1px solid #d6003a; */
.popcontext .tab li:nth-child(2) {
}

.popcontext .tab li:last-child {
  /* -webkit-border-bottom-left-radius: 5px;
      -moz-border-bottom-left-radius: 5px;
      border-bottom-left-radius: 5px; */
}

.popcontext .tab li:nth-child(2).active {
  border-top: 1px solid #f9f9f9;
}
.popcontext .tab li.active {
  border-left: 4px solid #d6003a;
  background-color: #d70039 !important;
  font-size: 16px;
  color: #ffffff;
  font-weight: bold;
  margin-left: 0;
  padding-left: 10px;
  border-right: 1px solid #fff;
  /* -webkit-border-radius: 3px 0 0 3px;
      -moz-border-radius: 3px 0 0 3px; */
  /* border-radius: 3px 0 0 3px; */
  filter: alpha(Opacity=100);
  -moz-opacity: 1;
  opacity: 1;
}
.popcontext hgroup {
  display: none;
  position: absolute;
  width: 460px;
  top: -410px;
  left: 0px;
  z-index: 1000;
  margin-left: 140px;
  background-color: #fff;
  /* -webkit-border-radius: 0 5px 5px 0;
      -moz-border-radius: 0 5px 5px 0; */
  /* border-radius: 0 5px 5px 0; */
}
.popcontext hgroup article {
  position: relative;
  height: 364px;
  padding-left: 30px;
}
</style>